import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

//components
import Layout from "../components/layout";
import Seo from "../components/seo";


const CaseStudies = (props) => {

	const { data, pageContext } = props

	const SEOdata = data.pageData.edges[0].node.frontmatter?.seo_metadata
	const Schemadata = data.pageData.edges[0].node.frontmatter?.Schema
	const Hreflang = data.pageData.edges[0].node.frontmatter?.Hreflang

	const posts = data.caseList.edges
	const { caseCurrentPage, caseNumPages } = pageContext
	const isFirst = caseCurrentPage === 1
	const isLast = caseCurrentPage === caseNumPages
	const prevPage = caseCurrentPage - 1 === 1 ? "" : (caseCurrentPage - 1).toString()
	const nextPage = (caseCurrentPage + 1).toString()

	return (
		<>
			<Layout>
				<Seo
					title={SEOdata ? SEOdata.seo_title : "case-studies"}
					description={SEOdata ? SEOdata.seo_description : ""}
					keywords={SEOdata ? SEOdata.seo_keyword : ""}
					image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
					single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
					currentPageUrlPath={props.location.pathname}
					image_format={SEOdata ? SEOdata.image_format : ""}
					image_height={SEOdata ? SEOdata.feature_image_height : ""}
					img_width={SEOdata ? SEOdata.img_width : ""}
					facebook_url={SEOdata ? SEOdata.facebook_url : ""}
					twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
					twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
					twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
					twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
					page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
					page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
					sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
					breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
					logo_schema={Schemadata ? Schemadata.logo_schema : ""}
					organization_schema={Schemadata ? Schemadata.organization_schema : ""}
					aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
					local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
					site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
					speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
					hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
					hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
					hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
					hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
				/>
				{/* title section */}

				<section className="pb-50 pt-1-25 lg:pt-1-26 lg:pb-46">
					<div className="container mx-auto">
						<div className="mx-auto w-full xl:w-2/3 text-center">
							<h2 className="px-5 md:px-52 lg:px-36  mx-auto sm:w-2/3 xl:w-3/4 d-head-title d-head-our-work  text-rh1 sm:text-h3  lg:text-h2 font-poppings-bold text-cyan">
								Developing Digital Solutions <br></br>
								<span className="text-term-primary">To Real-World Problems</span>
							</h2>
							<p className="research-para pt-3-1 px-5-1 sm:px-65 md:px-0 lg:px-0 mx-auto text-p4 lg:text-p2 font-worksans-normal text-term-primary">
								At DecodeUp, one of the major components of our process is
								detailed case studies, conducted to understand how digital
								technology can best be leveraged to deliver powerful results.
							</p>
						</div>
					</div>
				</section>
				{/* case studies listing */}
				<section>
					<div className="container mx-auto">
						{posts &&
							posts.map(({ node: post }, index) => (
								<div key={index} className="research-contain" style={{ background: post.frontmatter.short_details.bg_color_light }}>
									<Link to={post.frontmatter.slug} className="d-research-app-link block">
										<div className=" pt-6-6 2sm:pt-0 2xl:pb-55 2sm:pb-0 mb-35 lg:mb-36 mt-0 md:pb-0 md:pl-10 lg:pl-39  xl:pl-60 2xl:pl-70 xl:h-5-00 flex flex-wrap items-center justify-between">
											<div className={`px-5 md:px-0 lg:pr-10 sm:ml-auto  w-full  2sm:w-1/2`}>
												<h4 className="text-rh4 lg:text-h4 font-poppings-normal text-celeste">{post.frontmatter.title}{" - "}{post.frontmatter.short_details.heading}</h4>
												<h6 className="text-rh6 lg:text-h6 uppercase font-poppings-bold text-white ">{post.frontmatter.country}</h6>
												<div className="pt-1 lg:pt-5-1">
													<p className="sm:text-p2 text-rp1 lg:text-p1 font-worksans-bold text-white">{post.frontmatter.short_details.excerpt}</p>
												</div>
											</div>
											<div className="mt-10 2sm:mt-0 w-full 2sm:w-1/2  2xl:w-5-80 2xl:h-500">
												<div>
													<GatsbyImage image={getImage(post.frontmatter.short_details.featured_image.image)} alt={post.frontmatter.short_details.featured_image.alt} />
												</div>
											</div>
										</div>
									</Link>
								</div>
							))}
					</div>
				</section>

				{/* pagination */}
				{/* {caseNumPages > 1 && */}
				{!isFirst || caseNumPages > 1 || !isLast ?
					<div className="pt-5 pb-5 lg:pt-60 lg:pb-65 xl:pt-84 xl:pb-1-15 blog-pagination">
						<ul className="pagination">
							{!isFirst && (
								<li className="page-next">
									<Link className="next" to={`/case-studies/${prevPage}`}>
										<svg
											className="transform rotate-180 stroke-current text-dark-grey"
											width="20"
											height="14"
											viewBox="0 0 20 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
										</svg>
									</Link>
								</li>
							)}
							{caseNumPages > 1 &&
								(Array.from({ length: caseNumPages }, (_, i) => (
									<li
										key={`pagination-number${i + 1}`}
										className="page-number" >
										<Link
											to={`/case-studies/${i === 0 ? "" : i + 1}`}
											style={{
												color:
													i + 1 === caseCurrentPage ? "#ffffff" : "var(--text-term-secondary)",
												background: i + 1 === caseCurrentPage ? "#00AEEF" : "",
											}}>
											{i + 1}
										</Link>
									</li>
								)))}
							{!isLast && (
								<li className="page-next">
									<Link className="next" to={`/case-studies/${nextPage}`}>
										<svg
											className="stroke-current text-dark-grey"
											width="20"
											height="14"
											viewBox="0 0 20 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg" >
											<path d="M0 7L19.356 6.99058M13.0498 1L19.7095 6.9656L13.456 13.3186" />
										</svg>
									</Link>
								</li>
							)}
						</ul>
					</div>
					:
					''
				}
				{/* }	 */}
			</Layout>
		</>
	)
}

export default CaseStudies;

export const pageQuery = graphql`
query pageQuery($skip: Int!, $limit: Int!) {
	caseList: allMarkdownRemark(
	  sort: { order: DESC, fields: [frontmatter___date] }
	  filter: {
		frontmatter: {
		  templateKey: { eq: "single-case-study" }
		  title: { ne: "dummy" }
		}
	  }
	  limit: $limit
	  skip: $skip
	) {
	  edges {
		node {
		  id
		  fields {
			slug
		  }
		  frontmatter {
			country
			slug
			title
			short_details {
			  heading
			  excerpt
			  bg_color_light
			  bg_color_dark
			  featured_image {
				alt
				image {
				  childImageSharp {
					gatsbyImageData(placeholder: BLURRED)
				  }
				}
			  }
			}
		  }
		}
	  }
	}
	pageData: allMarkdownRemark(
	  filter: { fileAbsolutePath: { regex: "/pages/case-studies.md/" } }
	) {
	  edges {
		node {
		  frontmatter {
			seo_metadata {
			  seo_title
			  seo_keyword
			  seo_description
			  image_format
			  feature_image_height
			  img_width
			  facebook_url
			  twitter_page_username
			  twitter_image_alt_tag
			  twitter_estimated_reading_lable
			  twitter_estimated_reading_time
			  page_publish_date
			  page_last_modified_time
			  seo_Image {
				childImageSharp {
				  gatsbyImageData(layout: FIXED, width: 1200)
				}
			  }
			  seo_Single_Image
			}
			Schema {
			  sitelinks_schema
			  breadcrumb_schema
			  logo_schema
			  organization_schema
			  aggregate_rating_schema
			  local_business_schema
			  site_navigation_schema
			  speakable_specification_schema
			}
			Hreflang {
				hreflang_1
				hreflang_2
				hreflang_3
				hreflang_4
			}
		  }
		}
	  }
	}
  }
  
`